@import "react-datepicker/dist/react-datepicker.css";
/* Overriding the 3rd party style*/
.react-datepicker-wrapper {
  width: 15rem !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p {
    @apply mb-4 text-left text-xl;
  }
  h1 {
    @apply text-5xl font-medium mt-0 mb-2;
  }
  h2 {
    @apply text-3xl font-medium mt-0 mb-2;
  }
  h3 {
    @apply text-2xl mt-0 mb-2;
  }
  h4 {
    @apply text-xl mt-0 mb-2;
  }
  h5 {
    @apply text-lg mt-0 mb-2;
  }
  tr {
    @apply border-b border-grey-grey30;
  }
  tbody > tr:last-of-type {
    @apply border-none;
  }
  th {
    @apply p-4 bg-grey-grey10 text-left text-black;
  }
  td {
    @apply p-3;
  }

  @font-face {
    font-family: TT-Commons-Pro;
    font-weight: 700;
    src: url("../fonts/TT Commons Pro/TT Commons Pro Bold.ttf");
  }
  @font-face {
    font-family: TT-Commons-Pro;
    font-weight: 500;
    src: url("../fonts/TT Commons Pro/TT Commons Pro Medium.ttf");
  }
  @font-face {
    font-family: TT-Commons-Pro;
    font-weight: 400;
    src: url("../fonts/TT Commons Pro/TT Commons Pro Regular.ttf");
  }
}

body,
#root {
  font-weight: 500;
  @apply h-full bg-primary-baseGreen;
}

.loading-animation::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: white;
  animation: shimmer 2s cubic-bezier(0.48, 0.15, 0.56, 0.97) infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
